import './index.scss'

$('[data-open="cmenu"]').on('click', function () {
  if($('header .hamburger').hasClass('active')){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('.header').removeClass('show-menu')
    $('header .menu-mask').fadeOut(200)
    $('body').css({
      'height':'auto',
      'overflow-y':'auto'
    })
  }else{
    $('header .hamburger').addClass('active')
    $('header .mob-header').addClass('show')
    $('.header').addClass('show-menu')
    $('header .menu-mask').fadeIn(200)
    $('body').css({
      'height':'100vh',
      'overflow-y':'hidden'
    })
  }
})
$('.mob-header ul li .sub-nav a').on('click',function(){
  setTimeout(function(){
    $('header .hamburger').removeClass('active')
    $('header .mob-header').removeClass('show')
    $('header .menu-mask').fadeOut(200)
  },200)
})

$('.mob-header ul li .title .iconfont').on('click',function(){
  let $parentEl = $(this).parents('li')
  if($parentEl.hasClass('active')){
    $parentEl.removeClass('active')
    $parentEl.find('.sub-nav').slideUp()
  }else{
    $parentEl.addClass('active').siblings().removeClass('active')
    $parentEl.find('.sub-nav').slideDown()
    $parentEl.siblings().find('.sub-nav').slideUp()
  }
})

!(function(){
  if($('#circle-ani').length > 0){
    var ir1 = getRandomInt(20, 80);
    var ir2 = getRandomInt(20, 80);
    $('#circle-ani .circle-bg-img.circle1').css({top:'0%',right: ir1+'%'})
    $('#circle-ani .circle-bg-img.circle2').css({top: '0%',right: ir2+'%'})
  }
})()

var mouseover_tid = [];
var mouseout_tid = [];
$(document).ready(function () {
  $(".header .right .nav ul li").each(function (index) {
    $(this).hover(
      // 鼠标进入
      function () {
        var _self = this;
        // 停止卷起事件
        clearTimeout(mouseout_tid[ index ]);
        // 当鼠标进入超过 0.2 秒, 展开菜单, 并记录到线程 ID 中
        mouseover_tid[ index ] = setTimeout(function () {
          $(_self).addClass('hover');
          let headerH = $(".header").outerHeight()
          if($(_self).find('.sub-nav').length > 0){
            let subNavH = $(_self).find('.sub-nav').outerHeight()
            $(".header .white-bg").css('height', headerH + subNavH +'px');
          }else{
            $(".header .white-bg").css('height', '100%');
          }
        }, 201);
      },
      // 鼠标离开
      function () {
        var _self = this;
        // 停止展开事件
        clearTimeout(mouseover_tid[ index ]);
        // 当鼠标离开超过 0.2 秒, 卷起菜单, 并记录到线程 ID 中
        mouseout_tid[ index ] = setTimeout(function () {
          $(_self).removeClass('hover');
          let headerH = $(".header").outerHeight()
          $(".header .white-bg").css('height','100%');
        }, 201);
      });
    });

  $('.mob-header ul li').each(function(index){
    if($(this).find('.sub-nav a').length > 0){
      $(this).find('.title .iconfont').css('display','flex')
    }
  })

  if($('#circle-ani').length > 0){
    // 监听circle-ani 是否有class:is-inview
    var mo = new MutationObserver(function(records){
      if($(records[0].target).hasClass('is-inview')){
        var t1 = getRandomInt(40, 60);
        var r1 = getRandomInt(50, 70);
        var t2 = getRandomInt(40, 70);
        var r2 = getRandomInt(40, 80);
        var rotate1 = getRandomInt(-180, 180);
        var rotate2 = getRandomInt(-180, 180);
        $('#circle-ani .circle-bg-img.circle1').css({
          top: t1+'%',
          right: r1+'%',
          // transform:'translate3d(100%,-100%,0) rotate('+rotate1+'deg)'
        })
        $('#circle-ani .circle-bg-img.circle2').css({
          top: t2+'%',
          right: r2+'%',
          // transform:'translate3d(100%,-100%,0) rotate('+rotate2+'deg)'
        })
      }
    });
    
    var element = document.getElementById('circle-ani');
    mo.observe(element, {'attributes': true});
  }
});


let beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  var scroH = $(document).scrollTop(); //滚动高度
  var viewH = $(window).height(); //可见高度
  var contentH = $(document).height(); //内容高度
  var footerTop = $('.footer').offset().top - viewH

  // 上下半圆动画
  // if (scroH > 10) {
  //   $('.circle-bg .top-bg').addClass('is-inview')
  // }else{
  //   $('.circle-bg .top-bg').removeClass('is-inview')
  // }
  if (scroH > footerTop) {
    $('.circle-bg .footer-bg').addClass('is-inview')
  }else{
    $('.circle-bg .footer-bg').removeClass('is-inview')
  }

  // header
  if(scroH > 150){
    $('.header').addClass('scroll') 
  }else{
    $('.header').removeClass('scroll')
  }

  //判断页面是上滚还是下滚
  let delta = scroH - beforeScroH;
  if (delta > 0) {
    if(scroH > 50){
      $('.header').addClass('trans')
    }else{
      $('.header').removeClass('trans')
    }
  }
  if (delta < 0) {
    $('.header').removeClass('trans')
  }

  beforeScroH = scroH;
});

// 生成随机数
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}