
$('.select input').on('click',function(){
  let $parent = $(this).closest('.select')
  $parent.siblings('.select').removeClass('hover')
  if(!$parent.hasClass('hover')){
    $parent.addClass('hover')
  }
})

$('.select .select-list li').on('click',function(){
  let that = $(this)
  let val = $(this).html()
  that.closest('.select').find('input').val(val)
  that.closest('.select').removeClass('hover')
  
  setTimeout(function(){
    that.addClass('on').siblings().removeClass('on')
  },200)
})

$('body').on('click',function(e){
  if($(e.target).parents('.select').length == 0){
    $('.select').removeClass('hover')
  }
})



// data-scroll滚动加载is-inview
let contTopArr = []
$(document).ready(function(){
  isInviewinit()
})
$(document).scroll(function () {
  isInviewScroll()
})

function getcontTopArr(){
  contTopArr = []
  $('[data-scroll]').each(function(index){
    contTopArr.push({
      'top':$(this).offset().top - $(window).height(),
      'h':$(this).outerHeight(),
      'isrepeat': $(this).attr('data-scroll-repeat') == ''? true : false
    })
  })
}
function isInviewinit(){
  getcontTopArr()
  // console.log($(window).height(),contTopArr)
  let scroH = $(document).scrollTop();
  $.each(contTopArr,function(index){
    if(this.top < scroH){
      $('[data-scroll]').eq(index).addClass('is-inview')
    }
  })
}
function isInviewScroll(){
  let scroH = $(document).scrollTop();
  let viewH = $(window).height();
  $.each(contTopArr,function(index){
    if(this.isrepeat){
      if(this.top < scroH && this.top + this.h + viewH*(6/5) > scroH){
        $('[data-scroll]').eq(index).addClass('is-inview')
      }else{
        $('[data-scroll]').eq(index).removeClass('is-inview')
      }
    }else{
      if(this.top <= scroH){
        $('[data-scroll]').eq(index).addClass('is-inview')
      }else{
        $('[data-scroll]').eq(index).removeClass('is-inview')
      }
    }
  })
}

$(window).resize(function(){
  getcontTopArr()
})
