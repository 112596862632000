import './index.scss'

$(document).ready(function(){
  $('footer .footer-nav-mob ul li').each(function(index){
    if($(this).find('.sub-nav a').length > 0){
      $(this).find('.title .iconfont').css('display','flex')
    }
  })
})
$('footer .footer-nav-mob ul li .title .iconfont').on('click',function(){
  let $parent = $(this).closest('li')
  if($parent.hasClass('active')){
    $parent.removeClass('active').find('.sub-nav').slideUp()
  }else{
    $parent.siblings().removeClass('active').find('.sub-nav').slideUp()
    $parent.addClass('active').find('.sub-nav').slideDown()
  }
})